@charset "UTF-8";

@font-face {
  font-family: "sistema-legislativo";
  src:url("fonts/sistema-legislativo.eot");
  src:url("fonts/sistema-legislativo.eot?#iefix") format("embedded-opentype"),
    url("fonts/sistema-legislativo.woff") format("woff"),
    url("fonts/sistema-legislativo.ttf") format("truetype"),
    url("fonts/sistema-legislativo.svg#sistema-legislativo") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "sistema-legislativo" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sistema-legislativo" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\61";
}
.icon-alerta:before {
  content: "\62";
}
.icon-anexo:before {
  content: "\63";
}
.icon-calendar:before {
  content: "\64";
}
.icon-limpar:before {
  content: "\65";
}
.icon-concursos:before {
  content: "\66";
}
.icon-config:before {
  content: "\67";
}
.icon-contratos:before {
  content: "\68";
}
.icon-despesa:before {
  content: "\69";
}
.icon-email:before {
  content: "\6a";
}
.icon-filtro:before {
  content: "\6b";
}
.icon-filtros:before {
  content: "\6c";
}
.icon-fone:before {
  content: "\6d";
}
.icon-home:before {
  content: "\6e";
}
.icon-pessoas:before {
  content: "\6f";
}
.icon-pen:before {
  content: "\70";
}
.icon-ordem:before {
  content: "\71";
}
.icon-ouvidoria:before {
  content: "\72";
}
.icon-pessoal:before {
  content: "\73";
}
.icon-whatsapp:before {
  content: "\74";
}
.icon-sic:before {
  content: "\75";
}
.icon-monitor:before {
  content: "\76";
}
.icon-microfone:before {
  content: "\77";
}
.icon-seta:before {
  content: "\78";
}
.icon-seguranca:before {
  content: "\79";
}
.icon-mesa:before {
  content: "\7a";
}
.icon-menu:before {
  content: "\41";
}
.icon-save:before {
  content: "\42";
}
.icon-relatorios:before {
  content: "\43";
}
.icon-lupa:before {
  content: "\44";
}
.icon-licitacoes:before {
  content: "\45";
}
.icon-receita:before {
  content: "\46";
}
.icon-question:before {
  content: "\47";
}
.icon-legislacao:before {
  content: "\48";
}
.icon-impressao:before {
  content: "\49";
}
.icon-power:before {
  content: "\4a";
}
.icon-trash:before {
  content: "\4b";
}
.icon-filtros-input:before {
  content: "\4c";
}
.icon-seta-direita:before {
  content: "\4d";
}
.icon-seta-esquerda:before {
  content: "\4e";
}
.icon-sort-arrow:before {
  content: "\4f";
}
.icon-upload:before {
  content: "\50";
}
.icon-reorder-arrow:before {
  content: "\51";
}
